import React, { useEffect, useState } from 'react'
import { Wrapper, Text } from './styles'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { MobileOnly, DesktopOnly, Timeline } from 'components'
import { 
  PaymentDeadline, 
  DestinationBankInfo, 
  BankTransferInstructions 
} from './components'
import PropTypes from 'prop-types'
import API, { TransactionURL } from 'commons/API'
import { TRANSACTION_STATUS } from './constants'
import { useIntl } from 'react-intl'

const propTypes = {
  location: PropTypes.object
}

const defaultProps = {
  location: {}
}

const BankTransferPendingView = ({ location }) => {
  const intl = useIntl()
  const { token: tokenDashboard, isLoading: isLoadingToken } = useSelector(({ tokenDashboard }) => tokenDashboard)
  const transactionId = useSelector(({ transaction }) => transaction.id)
  const [transaction, setTransaction] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [selectedBank, setSelectedBank] = useState({})

  const timelineList = {
    Checkout: {
      step: {
        onClick   : () => navigate('/checkout'),
        selected  : location.pathname.includes('/checkout'),
        completed : location.pathname.includes('/payment/bank-transfer-pending')
      },
      line: {
        active: location.pathname.includes('/payment/bank-transfer-pending')
      }
    },
    Payment: {
      step: {
        onClick   : () => navigate('/payment'),
        selected  : location.pathname.includes('/payment'),
        completed : location.pathname.includes('/payment/bank-transfer-pending')
      },
      line: {
        active: location.pathname.includes('/payment/bank-transfer-pending')
      }
    },
    Confirmation: {
      step: {
        onClick  : () => navigate('/payment/bank-transfer-pending'),
        selected : location.pathname.includes('/payment/bank-transfer-pending')
      }
    }
  }

  useEffect(() => {
    // get bank pending based on transaction id from localstorage
    if (tokenDashboard && transactionId && !isLoadingToken) {
      API.get(`${TransactionURL.BaseURL}/${transactionId}`, {
        headers: { authorization: `Bearer ${tokenDashboard.token}` }
      })
        .then((response) => {
          const transactionData = response.data.transaction
          const transactionStatus = transactionData.transaction_status.name
          const invoice = response.data.invoice
          setTransaction(transactionData)
          setSelectedBank(transactionData.transaction_brand)
          navigation({
            transactionStatus,
            transactionData,
            invoice
          })
          setIsLoading(false)
        })
    }
    // get bank transfer pending transaction from BE
    else if(tokenDashboard && !transactionId && !isLoadingToken) {
      API.get(TransactionURL.GetLatestPending, {
        headers: { authorization: `Bearer ${tokenDashboard.token}` }
      })
        .then((response) => {
          if(response.data) {
            const transactionData = response.data.transaction
            const transactionStatus = transactionData.transaction_status.name
            const invoice = response.data.invoice
            setTransaction(transactionData)
            setSelectedBank(transactionData.transaction_brand)
            navigation({
              transactionStatus,
              transactionData,
              invoice
            })
          }
          setIsLoading(false)
        })
    }
    else if (!tokenDashboard && !isLoadingToken) navigate('/pricing')

  }, [isLoadingToken, tokenDashboard, transactionId])

  const navigation = ({
    transactionStatus,
    transactionData,
    invoice
  }) => {
    if (transactionStatus === TRANSACTION_STATUS.SUCCESS) {
      navigate(`/payment/status/?transaction_id=${transactionData.id}`, {
        state: {
          transaction : transactionData,
          invoice     : invoice
        }
      })
    } 
    else if (transactionStatus === TRANSACTION_STATUS.FAILED) {
      navigate(`/payment/status/?transaction_id=${transactionData.id}`, {
        state: {
          transaction: transactionData
        }
      })
    }
  }

  return (
    <>
      <MobileOnly>
        <Timeline
          menu={{
            list   : timelineList,
            margin : {
              m1 : '0.5rem 0 0 0.5rem',
              m2 : '0.5rem 0 0 1.5rem',
              m3 : '0.5rem 0.5rem 0 0'
            }
          }}
        />
      </MobileOnly>

      <DesktopOnly>
        <div style={{ margin: '0 15rem' }}>
          <Timeline
            menu={{
              list   : timelineList,
              margin : {
                m1 : '0 0 0 1rem',
                m2 : '0 0 0 0.5rem',
                m3 : ''
              }
            }}
          />
        </div>
      </DesktopOnly>

      { 
        isLoading 
          ? null 
          : (
            <Wrapper>

              <div style={{ display: Object.keys(transaction).length > 0 ? 'none': 'block' }}>
                <Text
                  fontSize='1.5rem'
                  fontSizeMobile='0.9rem'
                  textCenter
                  bold
                >
                  { intl.formatMessage({ id: 'be21e86a4' }) }
                </Text>

                <Text textCenter>
                  { intl.formatMessage({ id: 'a34e5d28b' }) }
                </Text>
              </div>

              <div style={{ display: Object.keys(transaction).length > 0 ? 'block': 'none' }}>
                <PaymentDeadline transaction={ transaction } />
                <DestinationBankInfo 
                  selectedBank={ selectedBank } 
                  transaction={ transaction } 
                />
                <BankTransferInstructions 
                  selectedBank={ selectedBank } 
                  transaction={ transaction } 
                />
              </div>
            </Wrapper>
          ) 
      }
    </>
  )
}

BankTransferPendingView.propTypes = propTypes
BankTransferPendingView.defaultProps = defaultProps

export default BankTransferPendingView
