import styled from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'

export const BankAccountWrapper = styled.div`
  border-radius: 6px;
  border: solid 1px #bcbfc8;
  background-color: #ffffff;
  margin: 2rem 0;

  ${IS_FRAME_MOBILE} {
    margin: 1.5rem 0;
  }
`

export const Flex = styled.div`
  display: flex;
  margin: ${({ margin }) => margin};
  align-items: center;

  ${IS_FRAME_MOBILE} {
    margin: ${({ marginMobile }) => marginMobile};
  }
`

export const BankTransferWrapper = styled.div`
  padding: 0 1.5rem;
  border-bottom: 1px solid #bcbfc8;

  ${IS_FRAME_MOBILE} {
    padding: 0 0.75rem;
  }
`

export const BankAccountDetailWrapper = styled(BankTransferWrapper)``

export const BankTransferTextWrapper = styled.div`
  flex: 0 0 85%;

  ${IS_FRAME_MOBILE} {
    flex: 0 0 80%;
  }
`

export const BankLogoWrapper = styled.div`
  flex: 0 0 15%;

  ${IS_FRAME_MOBILE} {
    flex: 0 0 20%;
  }
`

export const BankLogo = styled.img`
  width: 100%;
`

export const KeyWrapper = styled.div`
  flex: 0 0 60%;

  ${IS_FRAME_MOBILE} {
    flex: 0 0 50%;
  }
`
