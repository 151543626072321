import React, { useState } from 'react'
import { InstructionMethodWrapper, InstructionMethodBox, Alert, Ol, Li } from './styles'
import { Text } from '../../styles'
import { BANK_TRANSFER_INSTRUCTIONS } from '../../constants'
import { formatMoney } from 'utils'
import PropTypes from 'prop-types'

const propTypes = {
  selectedBank: PropTypes.shape({
    brand_alias : PropTypes.string,
    brand       : PropTypes.string,
    code        : PropTypes.string
  }),
  transaction: PropTypes.object
}

const defaultProps = {
  selectedBank: {
    brand_alias : 'bni',
    brand       : 'Bank Negara Indonesia',
    code        : '009'
  },
  transaction: {}
}

const BankTransferInstructions = ({ 
  selectedBank,
  transaction 
}) => {
  const [showInstruction, setShowInstruction] = useState('atm')

  const formatInstruction = ({
    instruction,
    method,
    index
  }) => {
    if(selectedBank.brand_alias === 'bni' && method === 'atm' && index === 2) {
      return instruction + ' ' + formatMoney(transaction.amount, { currency: transaction.currency.name })
    }
    
    return instruction
  }

  return (
    <>
      <InstructionMethodWrapper>
        { Object.keys(BANK_TRANSFER_INSTRUCTIONS[selectedBank.brand_alias]).map((method) => (
          <InstructionMethodBox
            key={ `instruction-${method}` }
            onClick={ () => setShowInstruction(method) }
            isActive={ showInstruction === method }
          >
            <Text
              fontWeight='600'
              letterSpacing='0.25px'
              textCenter
              fontSizeMobile='0.6rem'
              color={ showInstruction === method ? '#ffffff' : '#222b49' }
            >
              { method }
            </Text>
          </InstructionMethodBox>
        )) }
      </InstructionMethodWrapper>

      { Object.keys(BANK_TRANSFER_INSTRUCTIONS[selectedBank.brand_alias]).map((method) => (
        <Alert
          key={ `method-${method}` }
          color='rgba(34, 43, 73, 0.1)'
          margin='1.5rem 0'
          style={{ display: showInstruction === method ? 'block' : 'none' }}
        >
          <Ol>
            { BANK_TRANSFER_INSTRUCTIONS[selectedBank.brand_alias][method].map((instruction, index) => (
              <Li key={ `instruction-${instruction}` }>
                <Text letterSpacing='0.29px'>
                  { formatInstruction({ instruction, method, index }) }
                </Text>
              </Li>
            )) }
          </Ol>
        </Alert>
      )) }
    </>
  )
}

BankTransferInstructions.propTypes = propTypes
BankTransferInstructions.defaultProps = defaultProps

export default BankTransferInstructions
