export const BANK_TRANSFER_INSTRUCTIONS = {
  bni: {
    atm: [
      'Select the "other" menu > Transfer > Saving Account',
      'Enter the bank account number above',
      'Enter the amount shown above',
      'Enter news (optional)',
      'Confirm Payment',
      'Other ATMs',
      'Select Transfers > Transfer to another Bank',
      'Enter (009) as the BNI code',
      'Enter paymetout > Select Confirmation'
    ],
    'internet banking': [
      'Log in to BNI internet banking: https://ibank.bni.co.id',
      'Select Transactions > Virtual Billing Account',
      'Enter BNI Virtual Account > Select account Number > Continue',
      'Enter the normal and the BNI M-Secure Code > Process'
    ],
    'mobile banking': [
      'Log in to BNI mobile banking',
      'Select "Transfer" menu, then select "Between BNI Accounts", select "Input New Account"',
      'Press "Continue", then "Continue" again',
      'Enter the amount shown above',
      'On the confirmation page, make sure the transaction data is correct then select "Yes"',
      'Enter your password'
    ],
    branch: [
      'Fill in BNI Virtual Account Number above',
      'Fill in your name, fill in the date, select Rupiah',
      'Hand over money and cash deposit slip to the counter',
      'Save a copy of the cash deposit slip as proof of order payment'
    ]
  },
  bca: {
    atm: [
      'Insert the card into the ATM machine',
      'Enter your 6 digit PIN',
      'Select "Other Transactions"',
      'Select "Transfer"',
      'Continue to "to the BCA Virtual Account"',
      'Enter BCA Virtual Account number above, then press "Correct"',
      'Enter the amount that will be more sophisticated, then press "Correct"',
      'Validate your payment. Make sure all the transaction details are correct, then select "Yes"',
      'Your payment has been completed. Press "No" to complete the transaction, or press "Yes" to make another transaction'
    ],
    'BCA Mobile': [
      'Please login to the BCA Mobile application',
      'Select "m-BCA", then enter the m-BCA access code',
      'Choose "m-Transfer"',
      'Continue to "BCA Virtual Account"',
      'Enter BCA Virtual Account number above, or choose from the Transfer List',
      'Then, enter the amount to be paid',
      'Enter your m-BCA PIN',
      'Transaction has been successful'
    ],
    'KlikBCA Pribadi': [
      'Please log in to the KlikBCA Individual application',
      'Enter your User ID and PIN',
      'Select "Transfer Funds"',
      'Select "Transfer to BCA Virtual Account"',
      'Enter BCA Virtual Account number above or select it from the Transfer List',
      'Enter the amount to be paid',
      'Payment validation. Make sure all data is correct, then enter the code obtained from KEYBCA APPLI 1, then click "Submit"',
      'Payment has been completed'
    ],
    'KlikBCA Bisnis': [
      'Please login at KlikBCA Bisnis',
      'Select "Fund Transfer"> "Transfer List"> "Add"',
      'Enter BCA Virtual Account number above, then "Send"',
      'Select "Transfer Funds"',
      'Continue to "To BCA Virtual Account"',
      'Select the source account and the destination BCA Virtual Account',
      'Then, enter the amount to be paid, then select "Submit"',
      'Payment Validation. Until this stage, it means that the data has been successfully inputted. Then select "save"',
      'Select "Transfer Funds"> "Authorize Transactions", then select the transaction to be authorized',
      'Payment has been completed'
    ]
  }
}

export const TRANSACTION_STATUS = {
  PENDING : 'Pending',
  FAILED  : 'Failed',
  SUCCESS : 'Success',
  CANCEL  : 'Cancel'
}
