import styled from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'
import { darkBlueGrey } from 'components/baseColor'

export const InstructionMethodWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InstructionMethodBox = styled.div`
  border-radius: 4px;
  border: solid 1px #bcbfc8;
  background-color: ${({ isActive }) => (isActive ? darkBlueGrey : '#ffffff')};
  text-transform: uppercase;
  padding: 1rem 0;
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;

  ${IS_FRAME_MOBILE} {
    padding: 0.5rem 0;z
    width: 22%;
  }
`
export const Alert = styled.div`
  padding: 0.25rem 1rem;
  border-radius: 0.4rem;
  background-color: ${({ color }) => color};
  display: block;
  margin: ${({ margin }) => margin};

  ${IS_FRAME_MOBILE} {
    margin: ${({ marginMobile }) => marginMobile};
  }
`

export const Ol = styled.ol`
  padding-inline-start: 2rem;

  ${IS_FRAME_MOBILE} {
    padding-inline-start: 1rem;
  }
`

export const Li = styled.li`
  margin: 0.75rem 0;
  color: ${({ color }) => color || darkBlueGrey};
  font-size ${({ fontSize }) => fontSize || '0.875rem'};

  ${IS_FRAME_MOBILE} {
    font-size ${({ fontSizeMobile }) => fontSizeMobile || '0.75rem'};
  } 
`
