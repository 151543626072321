import React from 'react'
import {
  BankAccountWrapper,
  BankTransferWrapper,
  BankTransferTextWrapper,
  BankAccountDetailWrapper,
  BankLogoWrapper,
  BankLogo,
  KeyWrapper,
  Flex
} from './styles'
import { Text } from '../../styles'
import { formatMoney } from 'utils'
import PropTypes from 'prop-types'
import bni from '../../media/bni-logo.png'
import bca from '../../media/bca-logo.png'
import { useIntl } from 'react-intl'

const propTypes = {
  selectedBank: PropTypes.shape({
    brand_alias : PropTypes.string,
    brand       : PropTypes.string,
    code        : PropTypes.string
  }),
  transaction: PropTypes.object
}

const defaultProps = {
  selectedBank: {
    brand_alias : 'bni',
    brand       : 'Bank Negara Indonesia',
    code        : '009'
  },
  transaction: {}
}

const DestinationBankInfo = ({ selectedBank, transaction }) => {
  const intl = useIntl()
  const bankLogo = () => {
    if(selectedBank.brand_alias === 'bni') return bni

    return bca
  }

  return(
    <BankAccountWrapper>
      <BankTransferWrapper>
        <Flex margin='1rem 0' marginMobile='0.75rem 0'>
          <BankTransferTextWrapper>
            <Text fontWeight='600'>{ intl.formatMessage({ id: 'bff6dbd1c' }) }</Text>
          </BankTransferTextWrapper>
          <BankLogoWrapper>
            <BankLogo
              src={ bankLogo() }
              alt={ selectedBank.brand }
            />
          </BankLogoWrapper>
        </Flex>
      </BankTransferWrapper>

      <BankAccountDetailWrapper>
        <Flex margin='1rem 0' marginMobile='0.75rem 0'>
          <KeyWrapper>
            <Text>{ intl.formatMessage({ id: '375b56638' }) }</Text>
          </KeyWrapper>
          <Text fontWeight='600'>{ selectedBank.brand }</Text>
        </Flex>

        <Flex margin='1rem 0' marginMobile='0.75rem 0'>
          <KeyWrapper>
            <Text>{ intl.formatMessage({ id: '718be6de8' }) }</Text>
          </KeyWrapper>
          <Text fontWeight='600'>{ transaction.card_number }</Text>
        </Flex>

        <Flex margin='1rem 0' marginMobile='0.75rem 0'>
          <KeyWrapper>
            <Text>{ intl.formatMessage({ id: '5f2ccdabb' }) }</Text>
          </KeyWrapper>
          <Text fontWeight='600'>{ selectedBank.code }</Text>
        </Flex>

        <Flex margin='1rem 0' marginMobile='0.75rem 0'>
          <KeyWrapper>
            <Text>{ intl.formatMessage({ id: 'b31fa4b17' }) }</Text>
          </KeyWrapper>
          <Text fontWeight='600'>{ formatMoney(transaction.amount, { currency: transaction.currency.name }) }</Text>
        </Flex>
      </BankAccountDetailWrapper>
    </BankAccountWrapper>
  )
}

DestinationBankInfo.propTypes = propTypes
DestinationBankInfo.defautlProps = defaultProps

export default DestinationBankInfo
