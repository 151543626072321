import styled, { css } from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'
import { darkBlueGrey } from 'components/baseColor'

export const Wrapper = styled.div`
  margin: 1rem 20rem 3rem;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #bcbfc8;
  padding: 3rem 2rem;

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 3rem;
    padding: 1rem;
  }
`

export const Text = styled.div`
  color: ${({ color }) => color || darkBlueGrey};
  font-size ${({ fontSize }) => fontSize || '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
  word-spacing: ${({ wordSpacing }) => wordSpacing || 'normal'};
  margin: ${({ margin }) => margin};

  ${IS_FRAME_MOBILE} {
    font-size ${({ fontSizeMobile }) => fontSizeMobile || '0.75rem'};
    letter-spacing: ${({ letterSpacingMobile }) => letterSpacingMobile || 'normal'};
    word-spacing: ${({ wordSpacingMobile }) => wordSpacingMobile || 'normal'};
    margin: ${({ marginMobile }) => marginMobile};
  } 

  ${({ bold }) => bold &&
    css`
      font-weight: bolder;
    `}

  ${({ textCenter }) => textCenter &&
    css`
      text-align: center;
    `}

  ${IS_FRAME_MOBILE} {
    letter-spacing: ${({ letterSpacingMobile }) => letterSpacingMobile || 'normal'};
  }
`
