import React from "react";
import Layout from "components/Layout";
import BankTransferPendingView from "domains/BankTransferPending";
import PropTypes from "prop-types";

const propTypes = {
  location: PropTypes.object,
};

const defaultProps = {
  location: {},
};

const BankTransferPending = ({ location }) => (
  <Layout>
    <BankTransferPendingView location={location} />
  </Layout>
);

BankTransferPending.propTypes = propTypes;
BankTransferPending.defaultProps = defaultProps;

export default BankTransferPending;
