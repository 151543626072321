import React from 'react'
import Countdown from 'react-countdown'
import dayjs from 'dayjs'
import { Text } from '../../styles'
import PropTypes from 'prop-types'

const propTypes = {
  transaction: PropTypes.shape({
    due_date: PropTypes.string
  })
}

const defaultProps = {
  transaction: {
    due_date: ''
  }
}

const PaymentDeadline = ({ transaction }) => {
  const date = new Date()
  date.setDate(date.getDate() + 3)

  return (
    <>
      <Text bold fontSize='1.25rem' fontSizeMobile='0.875rem' textCenter>
        Complete Payment In
      </Text>

      <Text
        textCenter
        fontSize='1rem'
        fontSizeMobile='0.875rem'
        color='#fa591d'
        margin='1.25rem 0 0.5rem'
        wordSpacing='1rem'
        wordSpacingMobile='2px'
      >
        Days Hours Minutes Seconds
      </Text>

      <Text
        textCenter
        bold
        fontSize='3rem'
        fontSizeMobile='1.25rem'
        color='#fa591d'
        letterSpacing='4px'
        letterSpacingMobile='6px'
      >
        <Countdown 
          key={ transaction.due_date } 
          date={ transaction.due_date } 
        />
      </Text>

      <Text textCenter fontSize='1rem' style={{ marginTop: '1rem' }}>
        Payment deadline
      </Text>

      <Text textCenter bold fontSize='1.25rem' fontSizeMobile='0.9rem' style={{ marginTop: '1rem' }}>
        { dayjs(transaction.due_date).format('dddd, DD MMMM YYYY HH:mm') }
      </Text>
    </>
  )
}

PaymentDeadline.propTypes = propTypes
PaymentDeadline.defaultProps = defaultProps

export default PaymentDeadline
